import React from "react";

import DrvCover from "../../Shared/DrvCover/DrvCover";
import DrvQuote from "../../Shared/DrvQuote/DrvQuote";
import DrvContent from "../../Shared/DrvContent/DrvContent";
import DrvSubFooter from "../../Shared/DrvSubFooter/DrvSubFooter";
import {DrvButton} from "../../Shared/DrvButton/DrvButton";
import {AboutUsData} from "./AboutUs.data";
import { SubFooterContent, SubFooterTitle} from "./AboutUs.styles";

import Cover from "../../../images/OurCompany/AboutUs/Cover.jpg";
import DrivosityUSA from "../../../images/OurCompany/AboutUs/DrivosityUSA.jpeg";
import DrivosityNorthMacedonia from "../../../images/OurCompany/AboutUs/DrivosityNorthMacedonia.jpeg";
import DrivositySerbia from "../../../images/OurCompany/AboutUs/DrivositySerbia.jpg";
import Brian from "../../../images/OurCompany/AboutUs/Brian2.png";



const AboutUs = () => {
    return (
        <>
            {/*CONTENT*/}
            <DrvCover
                title={AboutUsData[0].title}
                description={AboutUsData[0].content}
                color='#ffffff'
                image={Cover}
            />

            {/*CONTENT*/}
            <div className="py-12">
                <DrvQuote
                    image={Brian}
                    name={AboutUsData[1].data.name}
                    position={AboutUsData[1].data.position}
                    quote={AboutUsData[1].content}
                />
            </div>

            {/*CONTENT*/}
            <div className='container mx-auto drv-description'>
                {AboutUsData[2].content}
            </div>

            {/*CONTENT*/}
            <DrvContent
                type={['text']}
                content={[
                    (
                        <>
                            <div className='drv-subtitle'>
                                {AboutUsData[3].title}
                            </div>
                            <div className='drv-description'>
                                {AboutUsData[3].content}
                            </div>
                        </>
                    )
                ]}
            />

            {/*CONTENT*/}
            <div>
                <div className='container mx-auto drv-subtitle'>
                    ONE TEAM. GLOBAL PRESENCE
                </div>
                <div className="flex justify-center" style={{backgroundColor: '#f8f8f8'}}>
                    <div className="grid grid-cols-1 lg:grid-cols-3">
                        <div>
                            <img src={DrivosityUSA} alt="USA Team" />
                        </div>
                        <div>
                            <img src={DrivosityNorthMacedonia} alt="North Macedonia Team" />
                        </div>
                        <div>
                            <img src={DrivositySerbia} alt="Serbia Team" />
                        </div>
                    </div>
                </div>
            </div>

            {/*CONTENT*/}
            {/* <div>
                <DrvContent
                    type={['text']}
                    content={[
                        (
                            <>
                                <div className='drv-subtitle'>
                                    {AboutUsData[4].title}
                                </div>
                                <div className='drv-description'>
                                    {AboutUsData[4].content}
                                </div>
                            </>
                        )
                    ]}
                />
                <div className="container mx-auto flex justify-center pb-12">
                    <div className="grid-rows-1">
                        <div className="flex justify-center">
                            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2" >
                                <DrvCardTeam team={[Team[0]]} />
                            </div>
                        </div>
                        <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3">
                            <DrvCardTeam team={[Team[2], Team[3], Team[4]]} />
                        </div>
                        <div className="flex justify-center">
                            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2">
                                <DrvCardTeam team={[Team[5], Team[6]]} />
                            </div>
                        </div> 
                    </div>
                </div>
            </div> */}

            {/*CONTENT*/}
            <DrvSubFooter
                color="#f5f5f7"
                background="var(--primary-color)"
                description={
                    <div className='flex flex-col'>
                        <SubFooterTitle>{AboutUsData[5].title}</SubFooterTitle>
                        <SubFooterContent>{AboutUsData[5].content}</SubFooterContent>
                    </div>
                }
                button={
                    <DrvButton to="/careers" background="var(--primary-color)" bordercolor="white" className="w-fit">LEARN MORE</DrvButton>
                }
            />
        </>
    )
}
export default AboutUs;
